<template>
  <div class="nudeChatPage unlockZone">
    <!-- 轮播图 -->
    <AdvSwiper :advList="louFengAdvList"></AdvSwiper>
    <div class="stickyBox">
      <!-- 跑马灯 -->
      <div class="marquee">
        <div class="offcialAnnounce">官方公告</div>
        <div class="marquee-box">
          <van-notice-bar class="noticeBar" background="white" color="black" speed="40" :text="marqueeText"/>
        </div>
      </div>
      <!-- 筛选 -->
      <div class="filterBox">
        <div class="filterOpt">
          <div class="filterItem" :class="{active: ageActive == index}" v-for="(item, index) in ageArr" :key="index" @click="chabgeAgeItem(index)">{{item}}
          </div>
        </div>
        <div class="filterOpt">
          <div class="filterItem" :class="{active: heightActive == index}" v-for="(item, index) in heightArr" :key="index" @click="changeHeightItem(index)">{{item}}
          </div>
        </div>
        <div class="filterOpt">
          <div class="filterItem" :class="{active: cupActive == index}" v-for="(item, index) in cupArr" :key="index" @click="changeCupItem(index)">{{item}}
          </div>
        </div>
      </div>
    </div>
    <div class="stepping"></div>
    

    <div class="chatListBox">
      <Pullrefresh @onLoad="onLoad" :loading="loading" :finished="finished" @onRefresh="onRefresh" :refreshing="refreshing" :firstLoading="firstLoading" :isNoData="isNoData" :isHigehtMax="false" >
        <div class="chatList">
          <div class="chatItem" v-for="(item, index) in nudeChatList" :key="index" @click="toDetails(item)">
            <div class="topBox">
              <ImgDecypt class="avatar" :src="item.imageCover[0]"></ImgDecypt>
              <div class="chatNum">{{item.countPurchases || 0}}人连线过</div>
            </div>
            <div class="bottomBox">
              <div class="name">{{item.title}}</div>
              <div class="chatInfo">
                <span class="chatInfoItem">{{item.age ? item.age + '岁' : ''}}</span>
                <span class="chatInfoItem">{{item.height ? item.height + 'cm' : ''}}</span>
                <span class="chatInfoItem">{{cupArr[item.cup]}}</span>
              </div>
              <div class="labelBox">
                <div class="labelItem" v-for="(i, j) in item.tags" :key="j">
                  <p>{{i}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Pullrefresh>
    </div>
  </div>
</template>

<script>
import AdvSwiper from '@/components/AdvSwiper/index';
import { queryNudeChat, } from '@/api/nudeChat.js';
import Pullrefresh from "@/views/widget/PullRefresh.vue";
export default {
  name: 'unlockZone',
  components: {
    AdvSwiper,
    Pullrefresh,
  },
  props: {
    louFengAdvList: [],
    loufengType: String
  },
  data() {
    return {
      marqueeText: '', // 跑马灯数据
      pageNum: 1, // 页码
      pageSize: 10, // 列表
      ageArr: ["全部", "18~25", "26～30", "31以上"], // 年龄筛选选项
      heightArr: ["全部", "155cm~165cm", "166cm~175cm", "176cm以上"], // 身高筛选选项
      cupArr: ["全部", "C罩杯", "D罩杯", "E罩杯", "F罩杯"], // cup筛选选项
      cupActive: 0, // 选中cup下标
      ageActive: 0, // 选中年龄下标
      heightActive: 0, // 选中身高下标
      nudeChatList: [], // 裸聊列表
      loading: false, // loading状态
      finished: false, // 是否还有更多状态
      refreshing: false, // 刷新状态
      isNoData: false, // 是否有数据
      firstLoading: true, //是否第一次加载
    }
  },
  created() {
    this.queryNudChat();
  },
  methods: {
    async queryNudChat() {
      let req = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        ageInterval: this.ageActive,
        heightInterval: this.heightActive,
        cup: this.cupActive,
      };
      this.loading = true;
      this.isNoData = false;
      let ret = await this.$Api(queryNudeChat, req);
      if (ret && ret.code == 200) {
        let list = ret.data.list ? ret.data.list : [];
        this.marqueeText = ret.data.marquee;
        this.nudeChatList = this.nudeChatList.concat(list)
        if (list.length == 0 && this.pageNum == 1) {
          this.isNoData = true;
          this.loading = false;
          this.refreshing = false;
          this.firstLoading = false;
          return;
        }
        if (list.length < this.pageSize) {
          this.finished = true;
        }
      }
      this.loading = false;
      this.refreshing = false;
      this.firstLoading = false;
    },
    // 更改年龄筛选选项
    chabgeAgeItem(index) {
      this.ageActive = index;
      this.pageNum = 1;
      this.pageSize = 10;
      this.nudeChatList = [];
      this.firstLoading = true;
      this.queryNudChat();
    },
    // 更改身高筛选选项
    changeHeightItem(index) {
      this.heightActive = index;
      this.pageNum = 1;
      this.pageSize = 10;
      this.nudeChatList = [];
      this.firstLoading = true;
      this.queryNudChat();
    },
    // 更改cup筛选选项
    changeCupItem(index) {
      this.cupActive = index;
      this.pageNum = 1;
      this.pageSize = 10;
      this.nudeChatList = [];
      this.firstLoading = true;
      this.queryNudChat();
    },
    //上滑加载
    onLoad() {
      this.pageNum++;
      this.queryNudChat();
    },
    //刷新
    onRefresh() {
      this.refreshing = true;
      this.finished = false;
      this.pageNum = 1;
      this.nudeChatList = [];
      this.queryNudChat();
    },
    // 跳转详情
    toDetails(item) {
      this.$router.push({ path: "/nudeChatDetails", query: { cid: item.id } });
    },
    // scroll(e) {
    //   let dom = e.target;
    //   let sticky = document.getElementsByClassName('sticky-box')[0];
    //   let stepping = document.getElementsByClassName('stepping')[0];
    //   if(dom.scrollTop > 150) {
    //     sticky.style.position = 'fixed';
    //     sticky.style.top = 0;
    //     stepping.style.height = '70px';
    //   } else {
    //     sticky.style.position = 'static';
    //     stepping.style.height = '0';
    //   }
    // }
  }
}
</script>

<style scoped lang="scss">
  .nudeChatPage {
    height: calc(100vh - 90px);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
  .marquee{
    display: flex;
    padding-top: 7px;
    padding: 10px 10px;
    .offcialAnnounce {
      height: 24px;
      width: 90px;
      background-image: linear-gradient(to right, rgb(234, 42, 42), rgb(239, 169, 58));
      line-height: 24px;
      color: white;
      text-align: center;
      font-size: 12px;
      border-radius: 4px;
      flex: 1;
    }
    .marquee-box {
      flex: 4;
      .noticeBar {
        height: 24px;
      }
    }
  }
  .tabNav {
    width: 100%;
    height: 30px;
    ::v-deep .van-tab{
      font-size: 14px;
      ::v-deep .van-tab--active {
        font-size: 16px;
      }
    }
  }
  .tabNavBox {
    position: relative;
  }
  .location {
    position: absolute;
    right: 15px;
    top: 7px;
    font-size: 14px;
  }
  /deep/ .van-tabs--line .van-tabs__wrap {
    height: 30px;
    width: 120px;
  }
  ::v-deep .van-tab .van-tab--active {
    font-size: 16px;
  }
  ::v-deep .van-sticky {
    background-color: white;
  }
  /deep/ .van-sticky--fixed {
    top: 0px !important;
    padding: 0 10px;
  }
  .filterBox {
    margin: 0 10px;
    height: 86px;
    border-radius: 12px;
    font-size: 12px;
    // padding: 16px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .active {
      font-weight: 500;
      position: relative;
    }
  }
  .filterOpt {
    display: flex;
  }
  .filterItem {
    // margin-right: 22px;
    padding: 4px 10px;
    border-radius: 10px;
  }
  .filterBox .active {
    font-weight: 500;
    color: #ffffff;
    background: $vermillion;
    position: relative;
  }

  .chatList {
    margin: 0 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .chatItem {
      width: 172px;
      height: 318px;
      border-radius: 6px;
      background: #ffffff;
      overflow: hidden;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16);
      margin-bottom: 10px;
    }
    .topBox {
      position: relative;
      width: 100%;
      height: 234px;
      .chatImg {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .chatNum {
        height: 20px;
        line-height: 20px;
        font-size: 10px;
        border-radius: 20px;
        padding: 0 4px;
        box-sizing: border-box;
        text-align: center;
        background: rgba(0, 0, 0, 0.45);
        color: $white1;
        position: absolute;
        top: 9px;
        left: 6px;
      }
    }
    .bottomBox {
      padding: 5px 12px;
      height: 74px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .name {
        font-size: 13px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        // margin-bottom: 4px;
      }
      .chatInfo {
        // margin-top: 4px;
        font-size: 10px;
        color: #7d7d7d;
        .chatInfoItem {
          margin-right: 4px;
        }
      }
      .labelBox {
        display: flex;
        .labelItem {
          margin-right: 5px;
          font-size: 10px;
          padding: 0 10px;
          height: 16px;
          line-height: 16px;
          border-radius: 16px;
          background: #ff0000;
          color: $white1;
          p {
            min-width: 21px;
          }
        }
      }
    }
  }
  .chatListBox {
    height: calc(100vh - 250px);
  }
  .stickyBox {
    position: sticky;
    top: 0;
    z-index: 10;
    background: $white1;
    padding-bottom: 10px;
  }
</style>
