<template>
  <div class="unlockZone scrollTip" @scroll="scroll">
    <!-- 轮播图 -->
    <AdvSwiper :advList="louFengAdvList"></AdvSwiper>
    <div class="sticky-box">
      <!-- 跑马灯 -->
      <div class="marquee">
        <div class="offcialAnnounce">官方公告</div>
        <div class="marquee-box">
          <van-notice-bar class="noticeBar" background="white" speed="40" color="black" :text="marqueeText"/>
        </div>
      </div>
      <!-- tab导航栏 -->
      <div class="tabNavBox">
        <van-tabs v-model="active" class="tabNav" @click="toggle">
          <van-tab title="推荐"></van-tab>
          <van-tab title="最新"></van-tab>
        </van-tabs>
        <div class="location" @click="toLoufengLocation">
          <van-icon name="location" color="red" size="15" />&nbsp;&nbsp;{{ location }}
        </div>
      </div>
    </div>
    <div class="stepping"></div>
    <div class="main">
      <!-- 楼凤信息 -->
      <van-swipe class="my-swipe" indicator-color="white" ref="loufengSwipe" :touchable="false">
        <van-swipe-item v-if="isActive1">
          <LouFengInfo v-if="active === 0" :loufengType="loufengType" loufengSort="recommend" ref="loufengInfo" @getMarquee="getMarquee"></LouFengInfo>
        </van-swipe-item>
        <van-swipe-item v-if="isActive2">
          <LouFengInfo v-if="active === 1" :loufengType="loufengType" loufengSort="newest" ref="loufengInfo" @getMarquee="getMarquee"></LouFengInfo>
        </van-swipe-item>
      </van-swipe>
    </div>
  </div>
</template>

<script>
import AdvSwiper from '@/components/AdvSwiper/index';
import LouFengInfo from './LouFengInfo/index';

export default {
  name: 'unlockZone',
  components: {
    AdvSwiper,
    LouFengInfo
  },
  props: {
    louFengAdvList: [],
    loufengType: String
  },
  data() {
    return {
      active: 0,
      marqueeText: '', // 跑马灯数据
      location: '',
      isActive1: true,
      isActive2: true
    }
  },
  created() {
    this.location = localStorage.getItem('location');
    if(!this.location) {
      this.location = '全国';
    }
  },
  activated() {
    if(this.active === 1) {
      this.isActive1 = false;
    }
  },
  methods: {
    toggle() {
      if(this.active === 1) {
        this.$refs.loufengSwipe.swipeTo(1);
      } else {
        this.$refs.loufengSwipe.swipeTo(0);
        this.isActive1 = true;
      }
    },
    getMarquee(text) {
      this.marqueeText = text;
    },
    toLoufengLocation() {
      this.$router.push({
        name: 'loufengLocation'
      })
    },
    scroll(e) {
      let dom = e.target;
      let sticky = document.getElementsByClassName('sticky-box')[0];
      let stepping = document.getElementsByClassName('stepping')[0];
      if(dom.scrollTop > 150) {
        sticky.style.position = 'fixed';
        sticky.style.top = 0;
        stepping.style.height = '70px';
      } else {
        sticky.style.position = 'static';
        stepping.style.height = '0';
      }
    }
  }
}
</script>

<style scoped lang="scss">
.sticky-box{
  width: 100%;
  position: static;
  z-index:10;
  background:#fff;
}
  * {
    margin: 0;
    padding: 0;
  }
  .unlockZone {
    overflow: auto;
  }
  .main{
    height: calc(100vh - 250px);
    padding: 0 10px;
    position: relative;
  }
  .marquee{
    display: flex;
    padding-top: 7px;
    padding: 10px 10px;
    .offcialAnnounce {
      height: 24px;
      width: 90px;
      background-image: linear-gradient(to right, rgb(234, 42, 42), rgb(239, 169, 58));
      line-height: 24px;
      color: white;
      text-align: center;
      font-size: 12px;
      border-radius: 4px;
      flex: 1;
    }
    .marquee-box {
      flex: 4;
      .noticeBar {
        height: 24px;
      }
    }
  }
  .tabNav {
    width: 100%;
    height: 30px;
    ::v-deep .van-tab{
      font-size: 14px;
      ::v-deep .van-tab--active {
        font-size: 16px;
      }
    }
  }
  .tabNavBox {
    position: relative;
  }
  .location {
    position: absolute;
    right: 15px;
    top: 7px;
    font-size: 14px;
  }
  /deep/ .van-tabs--line .van-tabs__wrap {
    height: 30px;
    width: 120px;
  }
  ::v-deep .van-tab .van-tab--active {
    font-size: 16px;
  }
  ::v-deep .van-sticky {
    background-color: white;
  }
  /deep/ .van-sticky--fixed {
    top: 0px !important;
    padding: 0 10px;
  }
</style>
