<template>
  <div class="logFengInfo">
    <van-pull-refresh v-model="refreshing" success-text="刷新成功" @refresh="onRefresh">
      <template #loading>
        <div></div>
      </template>
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" :offset="40" :immediate-check="false"
        @load="onLoad" v-if="!isNoData">
        <div class="box">
          <div class="box-item" v-for="(item,index) in loufengData" :key="index" @click="jumpLouFengDetail(item.id)">
            <div class="infoBox">
              <div class="infobox-item payable" v-if="item.payable"></div>
              <div class="infobox-item top" v-if="item.topType === 9"></div>
              <div class="infobox-item boutique" v-if="item.topType === 8"></div>
              <div class="img">
                <ImgDecypt :src="item.img" fill="cover" />
              </div>
              <div class="title">
                {{ item.title }}
              </div>
              <div class="info">
                <span>{{ item.height }}cm.{{ item.age }}.{{ item.cup }}</span>
              </div>
              <div class="price">
                <span>价格：{{ item.srvPrice }}</span>
              </div>
            </div>
          </div>
        </div>
      </van-list>
      <!-- 没有数据的时候展示 -->
      <NoData v-if="isNoData" class="noData"></NoData>
    </van-pull-refresh>
    <!-- 首次数据加载 -->
    <van-loading type="spinner" color="#f21313" v-if="firstLoading">&nbsp;&nbsp;数据加载中...</van-loading>
  </div>
</template>

<script>
  import {
    getLouFengList
  } from '@/api/loufeng.js';
  import NoData from "@/components/NoData";

  export default {
    name: 'LouFengInfo',
    props: {
      loufengType: {
        type: String,
        default: '',
      },
      loufengSort: {
        type: String,
        default: '',
      }
    },
    components: {
      NoData
    },
    data() {
      return {
        pageNum: 1,
        pageSize: 10,
        loufengData: [],
        loading: false, //下滑loading
        finished: false, //数据是否加载完毕
        refreshing: false, //下拉下载loading
        isNoData: false, //数据是否为空
        firstLoading: true, //是否第一次加载
      }
    },
    created() {
      this.init();
    },
    methods: {
      // 初始化数据
      init() {
        this.getLouFengList(localStorage.getItem('location'), this.loufengSort, this.loufengType);
      },
      // 获取楼凤信息
      /**
       * @param city-城市，loufengSort - 'recommend、newest'，type - 'unlock、verified、payable'
       */
      async getLouFengList(city, loufengSort, type) {
        let req = {
          city: city,
          loufengSort: loufengSort,
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          type: type
        }
        let res = await this.$Api(getLouFengList, req);
        this.loading = false;
        this.refreshing = false;
        this.firstLoading = false;
        if (res && res.code === 200) {
          this.$emit('getMarquee', res.data.marquee);
          let loufengData = res.data.list;
          this.loufengData = this.loufengData.concat(loufengData);
          if (loufengData.length == 0 && this.pageNum == 1) {
            this.isNoData = true;
            this.finished = true;
            return;
          }
          if (res.data.list.length < this.pageSize) {
            this.finished = true;
          }
        }
      },
      // 数据加载
      onLoad() {
        this.pageNum++;
        this.init();
      },
      // 数据刷新
      onRefresh() {
        // 清空列表数据
        this.finished = false;
        // 重新加载数据
        // 将 loading 设置为 true，表示处于加载状态
        // this.loading = true;
        this.firstLoading = true;
        this.loufengData = [];
        this.pageNum = 1;
        this.init();
      },
      // 去楼凤详情页
      jumpLouFengDetail(id) {
        this.$router.push({
          name: 'loufengDetail',
          params: {
            id: id
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .logFengInfo {
    margin-top: 10px;
    padding: 0 0 20px 0;
  }

  .box {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .box-item {
      width: 49%;

      .infoBox {
        width: 100%;
        border-radius: 5px;
        box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.6);
        padding: 0 0;
        margin-bottom: 10px;
        position: relative;
        overflow: hidden;

        .img {
          width: 100%;
          text-align: center;
          height: 234px;

          /deep/ .van-image__img {
            border-radius: 5px 5px 0 0;
          }
        }

        .title {
          width: 150px;
          font-size: 13px;
          word-break: keep-all;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin: 4px 4px;
        }

        .info {
          width: 100%;
          font-size: 12px;
          color: rgba(0, 0, 0, 0.6);
          margin: 4px 4px;
        }

        .price {
          width: 100%;
          font-size: 12px;
          color: red;
          margin-top: 4px;
          margin-left: 4px;
          height: 22px;
          word-break: keep-all;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .infobox-item {
          position: absolute;
          z-index: 22;
          color: white;
          font-size: 13px;
          line-height: 24px;
          text-align: center;
          font-weight: 800;
        }

        // 可赔付
        .payable {
          left: 0px;
          top: 0px;
          height: 24px;
          width: 54px;
          background: url('../../../../assets/png/payable.png') center center;
          background-repeat: no-repeat;
          background-size: cover;
          border-radius: 5px 0 5px 0;
        }

        // 置顶
        .top {
          right: -4px;
          top: 0;
          height: 60px;
          width: 60px;
          border-radius: 5px 5px 0 5px;
          background: url('../../../../assets/png/top.png') center center;
          background-size: cover;
          background-repeat: no-repeat;
        }

        // 精品
        .boutique {
          right: -2px;
          top: 0;
          height: 60px;
          width: 60px;
          border-radius: 5px 5px 0 5px;
          background: url('../../../../assets/png/boutique.png') center center;
          background-size: cover;
          background-repeat: no-repeat;
        }
      }
    }
  }

  .noData {
    height: calc(100vh - 290px);
  }

  // 底部没有更多了
  /deep/ .van-list__finished-text {
    line-height: 30px;
    margin-bottom: 50px;
  }

  // 下拉loading
  /deep/ .van-loading {
    height: 100px;
  }
</style>