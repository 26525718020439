<template>
  <div class="loufeng">
    <iframe v-if="h5PageUrl" class="h5Page" :src="h5PageUrl" frameborder="0"></iframe>
    <div class="oldPage" v-else>
      <van-sticky>
        <van-tabs v-model="active" background="rgb(242,19,19)" title-inactive-color="rgba(255,255,255,0.8)"
          title-active-color="white" animated duration="0.3" @click="onClick" class="loufengTab">
          <van-tab title="解锁专区">
            <SpecialArea v-if="active === 0" :louFengAdvList="louFengAdvList" loufengType="unlock"></SpecialArea>
          </van-tab>
          <van-tab title="认证专区">
            <SpecialArea v-if="active === 1" :louFengAdvList="louFengAdvList" loufengType="verified"></SpecialArea>
          </van-tab>
          <van-tab title="赔付专区">
            <SpecialArea v-if="active === 2" :louFengAdvList="louFengAdvList" loufengType="payable"></SpecialArea>
          </van-tab>
          <van-tab title="裸聊专区">
            <NudeChat v-if="active === 3" :louFengAdvList="louFengAdvList" loufengType="payable"></NudeChat>
          </van-tab>
          <van-tab title-style="flex:none !important;">
            <template #title>
              <van-button color="rgb(242,19,19)" icon="search"></van-button>
            </template>
          </van-tab>
        </van-tabs>
      </van-sticky>
      <div class="search" @click="toLoufengSearch"></div>
    </div>

  </div>
</template>

<script>
  import SpecialArea from './SpecialArea/index';
  import NudeChat from './nudeChat';
  import {
    getAdItem,
    AdType
  } from "@/utils/getAdv.js";
  export default {
    name: 'loufeng',
    components: {
      SpecialArea,
      NudeChat
    },
    data() {
      return {
        active: 2,
        activePage: undefined,
        louFengAdvList: [],
        loufengType: '',
        h5PageUrl: '',
      }
    },
    created() {
      this.h5PageUrl = JSON.parse(sessionStorage.getItem("appConfig")).louFengH5;
      this.init();
      this.getActivePage();
    },
    methods: {
      // 获取当前页面的激活状态
      getActivePage() {
        let activePage = sessionStorage.getItem('activePage');
        if (activePage != this.active) {
          if (activePage === null) {
            this.active = 2;
          } else {
            this.active = parseInt(activePage);
          }
        } else {
          sessionStorage.setItem('activePage', parseInt(this.active));
        }
      },
      // 初始化数据
      init() {
        this.getLouFengAdvList();
      },
      // 获取楼凤广告
      getLouFengAdvList() {
        this.louFengAdvList = getAdItem(AdType.loufeng);
      },
      // 去楼凤搜索
      toLoufengSearch() {
        this.$router.push({
          name: 'loufengSearch'
        })
      },
      onClick(active) {
        sessionStorage.setItem('activePage', active);
      }
    }
  }
</script>

<style scoped lang="scss">
  .loufeng {
    
    height: 100%;
    width: 100%;

    /deep/ .van-sticky--fixed {
      z-index: 1;
    }
    .oldPage {
      height: 100%;
      width: 100%;
      overflow: scroll;
    }
    .h5Page {
      width: 100%;
      height: 100%;
    }

    .search {
      height: 45px;
      width: 60px;
      position: fixed;
      top: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .loufengTab {
    ::v-deep .van-tab--active {
      font-size: 18px;
      font-weight: 400;
    }
  }

  .van-tab {
    font-size: 15px !important;
  }

  .van-button__icon {
    font-size: 20px;
  }

  .van-button--normal {
    padding: 0 0.3rem;
  }

  .van-button {
    border-radius: 0;
  }
</style>